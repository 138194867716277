/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Navigation
        if (viewportWidth < 991) {
          $('.c-header__nav-trigger, .c-canvas__close').click(function(e){
            e.preventDefault();

            $('.c-canvas').toggleClass('is-active');
          });
        }

        // Remove empty p tags
        $('p:empty').remove();

        // External Links.
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Owl carousel
        if (viewportWidth < 767) {
          if ($('.c-related').length) {
            $('.c-related .owl-carousel').owlCarousel({
              items: 1,
              loop: true,
              dots: true,
              autoplay: true,
              autoHeight: false
            });
          }
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var viewportWidth = $(window).width(),
            headerTop = $('.c-header').offset().top,
            headerHeight = $('.c-header').outerHeight(true),
            adminbarHeight = $('#wpadminbar').outerHeight(true);

        if (viewportWidth >= 1024) {
          $(window).scroll(function(){
            var scrollY = $(this).scrollTop();

            // Sticky Menu
            if (scrollY >= headerTop) {
              $('.c-header').addClass('is-sticky');
              $('body').css('padding-top', '124px');
            } else {
              $('.c-header').removeClass('is-sticky');
              $('body').css('padding-top', 0);
            }
          });
        }

        // Owl carousel
        if (viewportWidth < 767) {
          $('.c-latest .owl-carousel').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoHeight: false
          });
        }
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
